<template>
  <div class="assigned">
    <v-radio-group
      v-model="computedValueType"
      :column="false"
    >
      <v-radio
        :label="$t(`t.Me`)"
        value="me"
        class="mr-4"
      ></v-radio>
      <v-radio
        :label="$t(`t.Specific`)"
        value="other"
      ></v-radio>
    </v-radio-group>
    <picker
      v-if="computedValueType !== 'me'"
      document-types="users"
      :selected-id.sync="computedValue"
      :clearable="false"
      itemText="fullname"
      :attach="false"
      :readonly="readonly"
    />
  </div>
</template>

<script>
export default {
  components: {
    Picker: () => import('@/components/picker')
  },
  computed: {
    computedValue: {
      get () { return this.dataValue },
      set (v) {
        this.dataValue = v
        this.dataDocumentEmit()
      }
    },
    computedValueType: {
      get () { return this.dataValueType },
      set (v) {
        this.dataValueType = v
        if (v === 'me') {
          this.dataValue = v
        }
        this.dataDocumentEmit()
      }
    }

  },
  data () {
    return {
      dataValue: null,
      dataValueType: null,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  inheritAttrs: false,
  methods: {
    emitDocument () {
      if (!this.lodash.isEqual(this.document, this.dataValue)) {
        this.$emit('update:document', this.lodash.cloneDeep(this.dataValue))
      }
    }
  },
  props: {
    document: String,
    disabled: Boolean,
    dense: Boolean,
    readonly: Boolean
  },
  watch: {
    document: {
      handler (v) {
        this.dataValue = v
        this.dataValueType = v === 'me' ? 'me' : 'other'

        this.dataDocumentEmit()
      },
      immediate: true
    }
  }
}
</script>
